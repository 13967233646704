import { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import axios from 'axios';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'
import "swiper/css";
import "./index.css";
import './App.css';
import Video from './Video';
import VideoJS from './VideoJS';
import MuteButton from './MuteButton';
import VolumeButton from './VolumeButton';
import VideoContainer from './VideoContainer';
import EndScreen from './EndScreen';
import useElementOnScreen from './hooks/useElementOnScreen'


const gaID = "G-J53FVDYJJ1";

ReactGA.initialize(gaID);
 
ReactGA.send("pageview");


function App() {
  const [muted, setMuted] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [nextIndex, setNextIndex] = useState(1)
  const [preloadProperty, setPreloadProperty] = useState('none')
  const [videos, setVideos] = useState([])
  const [videoCount, setVideoCount] = useState([])
  const [videoReady, setVideoReady] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [lastSlide, setLastSlide] = useState(false)
  const [initialSlide, setInitialSlide] = useState(true)
  const [userInteraction, setUserInteraction] = useState(false)
  const [currentVideo, setCurrentVideo] = useState({})
  const [openModal, setOpenModal] = useState(false);
  const [swiperState, setSwiperState] = useState(false);
  // const [wakeLockEnabled, setWakeLockEnabled] = useState(false);
  const [height, setHeight] = useState(0);
  const [isAndroid, setIsAndroid] = useState(false);
  const [dayOfWeek, setDayOfWeek] = useState('ref:thursday');
  const endSlideRef = useRef(null);

  const prodPolicyKey = "BCpkADawqM0aHFLcwbh_VcdUsNXA0T881fLZ0PMIGW4BpU-LJwWufAC5vk2cxSfrUzQyK9pJ275d2MtcLO0dNkZVtIbfBDne9r6KPeU7mxbDYveFFgUrjMyox6dQKPPTFEe4Nsvr7w7d_ebj";
  const stagingPolicyKey = "BCpkADawqM1I3JouH3HQHM2r3nBCJPUYAoGOZbA7ehTGxTPU7CfGEXGXu9Dj9JZvbIPi6JyadZsqZpKItMjEpbreuC4ing3_aCqIZAGczC7KSozcWWuxIof_Fv-GfenwPZ1yfslFHFiQZD6r";
  const stagingAccountID = "6415505002001"
  const prodAccountID = "6415502549001"

  ReactGA.pageview(window.location.pathname + window.location.search);

  const visibleOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
};

  const renderableOptions = {
     root: null,
     rootMargin: '800px',
     threshold: 0
  }

  const config = {
    headers:{
      'Accept': 'application/json;pk=' + prodPolicyKey,
    }
  };

  // let wakeLock = null;

  // const requestWakeLock = async () => {
  //   try {
  //     wakeLock = await navigator.wakeLock.request();
  //     console.log(wakeLock)
  //     wakeLock.addEventListener('release', () => {
  //       console.log('Screen Wake Lock released:', wakeLock.released);
  //     });

  //     console.log('Screen Wake Lock released:', wakeLock.released);
  //   } catch (err) {
  //     console.error(`${err.name}, ${err.message}`);
  //   }
  // };

  const setDeviceHeight = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf("android") > -1;


    if(isAndroid) {
      console.log('android device height')
      let h = Math.round(window.innerHeight - 85);
      setIsAndroid(true);
      setHeight(h);
    }else{
      setHeight(window.innerHeight)
    }

  }


  // const { toggleWakeLock, wakeLockActive } = useWakeLock();



  // const enableWakeMode = () => {
  //   if(!wakeLockEnabled){
  //     requestWakeLock();
  //     setWakeLockEnabled(true)
  //   }
  // }

  // window.addEventListener('touchstart', enableWakeMode, { passive: true });


  const getAllVideos = () => {
    const weekday = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];


    const d = new Date();
    let stringDay = weekday[d.getDay()];
    let dayOfWeek = "ref:" + stringDay
          axios.get(`https://edge.api.brightcove.com/playback/v1/accounts/${prodAccountID}/playlists/${dayOfWeek}?limit=75&offset=0`, config)
          .then((response) => {
            console.log(response.data.videos)

                let shuffledVideos = shuffle(response.data.videos)
                let a = [response.data.videos[2]]
                setVideos(shuffledVideos)
                setCurrentVideo(shuffledVideos[0])
            })
            .catch(error => console.error(`Error: ${error}`));
  }

  const getVideoCount = () => {

    axios.get(`https://cms.api.brightcove.com/v1/accounts/${prodAccountID}/counts/videos`, config)
    .then((response) => {
      console.log(response.data)
          setVideoCount(response.data)
      })
      .catch(error => console.error(`Error: ${error}`));
  }
  
  const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const onMuteClick = (event) => {
    event.preventDefault();
    var ulElems = document.getElementsByTagName("video");

    Array.from(ulElems).forEach(function (element) {
      element.muted = !muted
    });
    setMuted(!muted);
  };


  const handleVideoReady = (player) => {
    setVideoReady(true)
  }

  const getDayOfWeek = () =>{
    const weekday = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];


    const d = new Date();
    let stringDay = weekday[d.getDay()];
    let dayOfWeek = "ref:" + stringDay
    console.log(dayOfWeek)
    setDayOfWeek(dayOfWeek)
}


  useEffect(() => {
    window.addEventListener('resize', setDeviceHeight);

    return () => {
        window.removeEventListener('resize', setDeviceHeight);
      };
    }, []);



  useEffect(() => {
    setDeviceHeight();
    getAllVideos();
  }, []);


  const updateSlideChange = (swiper, videos, endSlideRef) => {
    setSwiperState(swiper)
    setActiveIndex(swiper.activeIndex)
   
    if(swiper.activeIndex == videos.length){

      window.dataLayer.push({
        event: 'swipe',
        eventProps: {
            category: "user",
            action: "swipe",
            label: "video",
            videoName: 'final slide',
            channelName: 'final slide'
    }})

    }else{
      window.dataLayer.push({
        event: 'swipe',
        eventProps: {
            category: "user",
            action: "swipe",
            label: "video",
            videoName: videos[swiper.activeIndex].name,
            channelName: videos[swiper.activeIndex].custom_fields.channel_name
        }
      });
    }

  

    if (swiper.activeIndex == videos.length){
      setLastSlide(true);
    }

    if(swiper.previousIndex == 0 && initialSlide == true){
      setInitialSlide(false)
    }
    setOpenModal(false);
  };



  return (
    <div>
      <Swiper
        className="verticalSlider"
        direction={"vertical"}
        style={{height: height, width: window.innerWidth}}
        onSlideChange={(swiper) => updateSlideChange(swiper, videos)}
       >
       {(!muted && videoReady) &&<VolumeButton playing={playing} onMuteClick={onMuteClick} />}
       {(muted&& videoReady) &&<MuteButton playing={playing}  onMuteClick={onMuteClick} />}
          {videos.map((video, index) => (
            <SwiperSlide key={video.id} virtualIndex={index}>
                  <VideoContainer
                    height={height}
                    video={video}
                    muted={muted}
                    videoIndex={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    userInteraction={userInteraction}
                    onVideoReady={handleVideoReady}
                    playing={playing}
                    setPlaying={setPlaying}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setMuted={setMuted}
                    slideChange={updateSlideChange}
                    swiperState={swiperState}
                  />
            </SwiperSlide>
          ))}
           <SwiperSlide key='lastSlide' virtualIndex={videos.length + 1 }>
            {lastSlide &&   <EndScreen > </EndScreen>}
          </SwiperSlide>
        
      </Swiper>  
    </div>
  );
}

export default App;