import {useState, useRef, useEffect} from 'react'
import './MuteButton.css'
import frameOne from './img/MutedFrameOne.png'
import frameTwo from './img/MutedFrameTwo.png'
import frameThree from './img/MutedFrameThree.png'
import frameFour from './img/MutedFrameFour.png'


const MuteButton = ({onMuteClick, playing}) => {
  const [mutedFrame, setMutedFrame] = useState(frameOne);
  const [counter, setCounter] = useState(0);
  const [bigCounter, setBigCounter] = useState(0);
  const [wasPaused, setWasPaused] = useState(false);
  const timeout = useRef(null)

useEffect(() => {
    const nextSlide = () => {
      if(counter == 0){
        setMutedFrame(frameOne)
        setCounter(1)
      }
      if(counter == 1){
        setMutedFrame(frameTwo)
        setCounter(2)
      }
      if(counter == 2){
        setMutedFrame(frameTwo)
        setCounter(3)
      }
      if(counter == 3){
        setMutedFrame(frameThree)
        setCounter(4)
      }
      if(counter == 4){
        setMutedFrame(frameThree)
        setCounter(5)
      }
      if(counter == 5){
        setMutedFrame(frameThree)
        setCounter(0)
        setBigCounter(bigCounter+1)
      } 
      if(bigCounter == 3){
        setMutedFrame(frameThree)
        setCounter(10)
      }
    };
      if(playing){
        timeout.current = setTimeout(nextSlide, 150);
      }
      if(!playing){
        setMutedFrame(frameThree)
      }
        
    return function () {
        clearTimeout(timeout.current);        
    };
  });
  
    return (
        <div onClick={onMuteClick}  className="muteButton">
            <img src={mutedFrame} className="muteImage" />
        </div>
    )
}

export default MuteButton
