import {useRef, useState, useEffect} from 'react';
import './TikTokLogo.css'

import sensicaltv from './img/sensicaltvtest.png'
import blueLogo from './img/BlueTikTok.png'

import wordFrameOne from './img/TikTokWordFrameOne.png'
import wordFrameTwo from './img/TikTokWordFrameTwo.png'
import wordFrameThree from './img/TikTokWordFrameThree.png'
import wordFrameFour from './img/TikTokWordFrameFour.png'
import wordFrameFive from './img/TikTokWordFrameFive.png'

import frameOne from './img/TikTokFrame1.png'
import frameTwo from './img/TikTokFrame2.png'
import frameThree from './img/TikTokFrame3.png'
import frameFour from './img/TikTokFrame4.png'
import frameFive from './img/TikTokFrame5.png'


const TikTokLogo = ({isPlaying, logoTop, height}) => {
  const [isChecked, setIsChecked] = useState(frameOne);
  const [isColor, setIsColor] = useState(wordFrameOne);
  const topLogo = useRef(null);
  const botLogo = useRef(null);
  const timeout = useRef(null);


useEffect(() => {
    const nextSlide = () => {
      if(isChecked == frameOne){
        setIsChecked(frameTwo)
        setIsColor(wordFrameTwo)
      }
      if(isChecked == frameTwo){
        setIsChecked(frameThree)
        setIsColor(wordFrameThree)
      }
      if(isChecked == frameThree){
        setIsChecked(frameFour)
        setIsColor(wordFrameFour)

      }
      if(isChecked == frameFour){
        setIsChecked(frameFive)
        setIsColor(wordFrameFive)

      }
      if(isChecked == frameFive){
        setIsChecked(frameOne)
        setIsColor(wordFrameOne)

      }
    };

    if (isPlaying){
        timeout.current = setTimeout(nextSlide, 90);
      }

    return function () {
      if (!isPlaying){
        clearTimeout(timeout.current);
      }
    };
  });



    return (

      <div className="logoSection" style={{height: height, width: window.innerWidth}} >
      {logoTop &&
        <div className="logoContainerTop" ref={topLogo}>
          <div className="tiktokLogoContainerTop">
            <img  src={isChecked} className="blended" style={{height: "3.8vh", width: "auto"}} />
            <img  src={isColor} className="wordLogo" style={{height: "1.8vh", width: "auto"}} />
          </div>
            <img  src={sensicaltv} style={{height: "1.6vh", width: "auto"}} className="sensicaltv" />
        </div>
        }

        {!logoTop &&

        <div className="logoContainerBottom"  ref={botLogo}>
          <div className="tiktokLogoContainerBot">
            <img  src={isChecked} className="blended" style={{height: "3.8vh", width: "auto"}} />
            <img  src={isColor} className="wordLogo" style={{height: "1.8vh", width: "auto"}} />
          </div>
            <img  src={sensicaltv} style={{height: "1.6vh", width: "auto"}} className="sensicaltvbot" />
        </div>
        }

      </div>
    )
}

export default TikTokLogo
