import {useState, useEffect, useRef} from 'react'
import './VolumeButton.css'
import frameOne from './img/VolumeFrameOne.png'
import frameTwo from './img/VolumeFrameTwo.png'
import frameThree from './img/VolumeFrameThree.png'


const VolumeButton = ({onMuteClick, playing}) => {
  const [volumeFrame, setVolumeFrame] = useState(frameOne);
  const [counter, setCounter] = useState(0);
  const [bigCounter, setBigCounter] = useState(0);
  const [wasPaused, setWasPaused] = useState(false);
  const timeout = useRef(null)

useEffect(() => {
    const nextSlide = () => {
      if(counter == 0){
        setVolumeFrame(frameOne)
        setCounter(1)
      }
      if(counter == 1){
        setVolumeFrame(frameTwo)
        setCounter(2)
      }
      if(counter == 2){
        setVolumeFrame(frameTwo)
        setCounter(3)
      }
      if(counter == 3){
        setVolumeFrame(frameThree)
        setCounter(4)
      }
      if(counter == 4){
        setVolumeFrame(frameThree)
        setCounter(5)
      }
      if(counter == 5){
        setVolumeFrame(frameThree)
        setCounter(0)
        setBigCounter(bigCounter+1)
      } 
      if(bigCounter == 3){
        setVolumeFrame(frameThree)
        setCounter(10)
      }
    };
      if(playing){
        timeout.current = setTimeout(nextSlide, 150);
      }
      if(!playing){
        setVolumeFrame(frameThree)
      }
        
    return function () {
        clearTimeout(timeout.current);        
    };
  });
  
    return (
        <div onClick={onMuteClick}  className="volumeButton">
            <img src={volumeFrame} className="volumeImage"/>
        </div>
    )
}

export default VolumeButton
