import { createRoot } from 'react-dom/client';

import './index.css';
// import Original from './Original';
import App from './App';

// import TagManager from 'react-gtm-module'


// const tagManagerArgs = {
//   gtmId: 'GTM-58XDVL6'
// }

// TagManager.initialize(tagManagerArgs)


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

  root.render(
      <App/>
  );

