import React, {useEffect, useState, useRef} from 'react';
import useElementOnScreen from './hooks/useElementOnScreen'
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoPlayButton from './VideoPlayButton'
import "./Video.css";

export const VideoJS = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [firstSlide, setFirstSlide] = useState(false);
  const {options, onReady, muted, sources} = props;
  const [videoPlayingCheck, setVideoPlayingCheck] = useState(false);
  const renderRange = (window.innerHeight * 4).toString() + 'px';


  const visibleOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
};

  const renderableOptions = {
    root: null,
    rootMargin: `${renderRange} 0px 0px 0px`,
    threshold: 0
  };

  const isVisibile = useElementOnScreen(visibleOptions, videoRef);
  const isRenderable = useElementOnScreen(visibleOptions, videoRef);


  useEffect(() => {

}, []);


  useEffect(() => {
  if (isVisibile) {
    if (!props.videoPlaying) {
          if (videoRef.current.currentTime != 0 ){
            videoRef.current.currentTime = 0;
          };

          console.log('Attempting Autoplay!')
            videoRef.current.muted = props.muted
            let promise = videoRef.current.play();

            if (promise !== undefined) {
              promise.then(_ => {
                // console.log('Autoplay started!')
                props.setVideoPlaying(true)
                setVideoPlayingCheck(true)
                setFirstSlide(false)
              }).catch(error => {
                // console.log('Autoplay with sound not allowed!');
                // console.log('Muting Video and Attempting Autoplay!');
                
                videoRef.current.muted = true;
                let secondAttempt = videoRef.current.play();
  
                if (secondAttempt !== undefined) {
                  secondAttempt.then(_ => {
                    // console.log('muted autoplay started')
                    props.setMuted(true)
                    props.setVideoPlaying(true)
                    setVideoPlayingCheck(true)
                    setFirstSlide(true)
                  }).catch(error => {
                    // console.log('Muted Autoplay not allowed!')
                    props.setVideoPlaying(false)
                    setVideoPlayingCheck(false)
                  });
                }
              });
            }
    }else {
      videoRef.current.pause();
      props.setVideoPlaying(false)
      setVideoPlayingCheck(false)
    }
  }else{
    videoRef.current.pause();
    props.setVideoPlaying(false)
    setVideoPlayingCheck(false)
  }

}, [isVisibile])



  useEffect(() => {
    if (videoPlayingCheck && !isVisibile){
        videoRef.current.pause();
        setVideoPlayingCheck(false)
        props.setVideoPlaying(false)
    }
  
  }, [videoPlayingCheck]);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      
      if (!videoElement) return;
      
      const player = playerRef.current = videojs(videoElement, options, () => {
        // videojs.log('player is ready');
        
        onReady && onReady(player);
      });

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      // const player = playerRef.current;
      // 
      // player.pause();
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);
  
  const onVideoClick = () => {
    if (props.videoPlaying) {
      videoRef.current.pause();
      props.setVideoPlaying(!props.videoPlaying)
    } else {
      videoRef.current.play();
      props.setVideoPlaying(!props.videoPlaying)
    }
  };

  return (
    <div data-vjs-player style={{height: props.height, width: window.innerWidth}}>
      <video title={props.title}  playsInline ref={videoRef} className='video-js'  />
      {(props.firstSlide) &&
        <img src={bigPlayButton} className="bigPlayButton" ></img>
      }
      <VideoPlayButton style={props.height} onVideoClick={onVideoClick} />
    </div>
  );
}

export default VideoJS;
