import React, { useEffect, useRef, useState } from "react";
import "./Video.css";

import VideoFooter from "./VideoFooter";
import VideoSidebar from "./VideoSidebar";
import useElementOnScreen from './hooks/useElementOnScreen'
import VideoPlayButton from "./VideoPlayButton";
import MuteButton from "./MuteButton";
import VolumeButton from "./VolumeButton";
import DetailModal from "./DetailModal";
import VideoJS from './VideoJS'
import videojs from 'video.js';
import TikTokLogo from "./TikTokLogo";






const VideoContainer = (props) => {
  const playerRef = React.useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [visible, setVisible] = useState(false);
  const [firstPlay, setFirstPlay] = useState(true);
  const containerRef = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const [hidePlayButton, setHidePlayButton] = useState(false);
  const [logoTop, setLogoTop] = useState(true);
  const [videoWatched, setVideoWatched] = useState(false)

  let poster = '';

  const videoJsOptions = {
    muted: props.muted,
    controls: false,
    playsinline: true,
    preload: 'metadata',
    loop: true,
    fill: true,
    poster: props.video.poster,
    sources: [props.video.sources[1]],
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      hls: {
          overrideNative: false,
      },
  },
  };


  const visibleOptions = {
    root: null,
    rootMargin: '0px',
    threshold: .8
  };

  const isVisibile = useElementOnScreen(visibleOptions, containerRef);


    useEffect(() => {
      if (props.video.custom_fields.channel_name == "GO WITH YOYO - Fitness Fun For Kids" || props.video.custom_fields.channel_name == "Beatbox Dads" ) {
        setHidePlayButton(true);
      }
    }, [])

    useEffect(() => {
        setVideoIndex(props.index)
        setLogoTop(true)
    }, [])


  useEffect(() => {

    if (isVisibile) {
      // console.log('container is visible')
      props.setActiveIndex(videoIndex)
      setVisible(true)
    }else{
      // console.log('container is not visible')

    }
  }, [props.slideChange])

  const handlePlayerReady = (player) => {
    props.onVideoReady()
    playerRef.current = player;

    player.on('ready', () => {
      if (visible) {
        // console.console.log('vis');
      }
    });

    player.on('loadedmetadata', () => {
      setVideoReady(true)
    });

    player.on('timeupdate', () => {
      if(playerRef.current.currentTime() > 5){
        setLogoTop(false);
      }else{
        setLogoTop(true);
      }

    


    });


    player.on('playing', () => {
      setVideoPlaying(true)
      setFirstPlay(false)
      props.setPlaying(true)


    });



    player.on('volumechange', () => {
      // videojs.log('player is playing');
      if (playerRef.current.muted()){
        props.setMuted(true)
      }
      // setVideoPlaying(true)
      // props.setFirstSlide(false)
      // props.setPlaying(true)


    });

    player.on('pause', () => {
      // videojs.log('player is paused');
      setVideoPlaying(false)
      props.setPlaying(false)


    });

    player.on('dispose', () => {
      // videojs.log('player will dispose');
    });
  };

  const handleUserCloudChange = (value) => {
    props.setOpenModal(value)
  }
  const handleUserModalChange = (value) => {
    props.setOpenModal(value)
  }

  return (
    <div  ref={containerRef} >
      {(!videoPlaying && firstPlay) && <img className="cover" style={{height: props.height, width: window.innerWidth}} src={props.video.poster}></img>}
      <VideoJS setMuted={props.setMuted} options={videoJsOptions} userInteraction={props.userInteraction} index={props.index} key={props.video.id} height={props.height} muted={props.muted} title={props.video.name} onReady={handlePlayerReady} videoPlaying={videoPlaying} setVideoPlaying={setVideoPlaying} />

      {(props.openModal && visible) && <DetailModal description={props.video.custom_fields.tt_blurb}  height={props.height}  onModalClick={handleUserModalChange} opened={props.openModal}/>}


      <VideoSidebar player={playerRef} hidePlayButton={hidePlayButton} height={props.height} video={props.video} opened={props.openModal}  onCloudClick={handleUserCloudChange} />
      {videoReady && <TikTokLogo setLogoTop={setLogoTop} logoTop={logoTop} height={props.height} isPlaying={videoPlaying} />}
      <VideoFooter />
    </div>
  );
};
export default VideoContainer;
