import React from 'react'
import './VideoFooter.css'

const VideoFooter = ({channel, description}) => {
    return (
        <div className="videoFooter">
            <div className="videoFooter_text">
                <div className="videoFooter_ticker">

                </div>
            </div>
        </div>
    )
}

export default VideoFooter
