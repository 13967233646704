import React, { useState, useEffect } from 'react'
import './VideoSidebar.css'
import favorite from './img/favorite.png'
import favoriteOutlined from './img/favoriteoutlined.png'
import heart from './img/hearts.png'
import heartOutlined from './img/outlineheart.png'
import cloudFilled from './img/cloudfilled.png'
import cloudOutlined from './img/cloudoutlined.png'
import playButton from './img/playButtonRight.png'

const defaultSeriesURL = "https://sensical.tv/showdetails/";


const VideoSidebar = ({video, player, onCloudClick, opened, hidePlayButton}) => {
  const [liked, setLiked] = useState(false)
  const [favorited, setFavorited] = useState(false);
  const [seriesURL, setSeriesURL] = useState({});

  const handleFavorited = () => {
    setFavorited(!favorited)
  }

  useEffect(() => {
    setSeriesURL(defaultSeriesURL + video.custom_fields.parent_series_id)
  }, [])


  const handleLinkClick = (video) => {
      dataLayer.push({
        'videoname': video.name,
        'seriesname': video.custom_fields.channel_name,
    })
      event.preventDefault
      player.current.pause();
      window.location.href = seriesURL
  }

    return (
        <div className="videoSidebar">

        {hidePlayButton &&
            <div className="videoSidebarButton">
                    <img className="profileImage" src={video.custom_fields.imgix_url}/>
                    </div>
            }
            {!hidePlayButton &&
                <div style={{backgroundColor: 'transparent'}} data-videoname={video.name} data-seriesname={video.custom_fields.channel_name} className="seriesLink" href={defaultSeriesURL + video.custom_fields.parent_series_id} onClick={() => { handleLinkClick(video) } }>
                              <img className="profileImage" data-videoname={video.name} data-seriesname={video.custom_fields.channel_name}  src={video.custom_fields.imgix_url}/>
                              <img className="play" src={playButton}/>
                </div>
                            }
            <div className="videoSidebarButton">
            {favorited ? (
              <img className="heartImage" data-videoname={video.name} data-seriesname={video.custom_fields.channel_name} src={heart} onClick={() => { handleFavorited(!favorited) } }/>
                  ) : (
              <img className="heartImage" data-videoname={video.name} data-seriesname={video.custom_fields.channel_name}   src={heartOutlined} onClick={() => { handleFavorited(!favorited) } }/>
        )}
            </div>
            <div className="videoSidebarButton">
            {opened ? (
              <img className="cloudImage" id={'series: ' + video.custom_fields.channel_name} src={cloudFilled} onClick={() => { onCloudClick(!opened) } }/>
                  ) : (
              <img className="cloudImageClose" src={cloudOutlined} onClick={() => { onCloudClick(!opened) } }/>
        )}
            </div>
        </div>
    )
}

export default VideoSidebar
