import React from 'react'
import './DetailModal.css'
import muteButton from './img/volume.png'


const DetailModal = ({description, opened, onModalClick, height}) => {
    return (
      <div className="modalSection" style={{height: height, width: window.innerWidth}} onClick={() => { onModalClick(!opened) } }>
        <div className="modalContainer">
            <div className="modalText">
              <div className="outline"></div>
              {description}
          </div>
        </div>
      </div>
    )
}

export default DetailModal
