import React from 'react'
import './VideoPlayButton.css'
import playButton from './img/playButton.png'


const VideoPlayButton = ({onVideoClick}) => {
    return (
        <div className="videoPlayButton" onClick={onVideoClick}>
        </div>
    )
}

export default VideoPlayButton
