import React, {useState, useEffect} from 'react'
import './EndScreen.css'


const EndScreen = ({onMuteClick}) => {

  const [imageSrc, setImageSrc] = useState('https://sensical-platform.imgix.net/production/shorts/endScreen1.gif?auto=format');


  useEffect(() => {
    let images = ['https://sensical-platform.imgix.net/production/shorts/endScreen1.gif?auto=format', 'https://sensical-platform.imgix.net/production/shorts/endScreen2.gif?auto=format', 'https://sensical-platform.imgix.net/production/shorts/endScreen3.png', 'https://sensical-platform.imgix.net/production/shorts/1.png']
    var src = images[Math.floor(Math.random()*images.length)];

    setImageSrc(src)
  }, [])

    return ( <>
          <img id="endSlide" src={imageSrc} className="endScreen"/>
              <a href="https://sensical.tv/main/home/seeall/1728661977921601470">
                <div className="endButton"> </div>
              </a>
          </>
      
    )
}

export default EndScreen
